<template>
    <p> Edit for event here </p>
</template>

<script>
export default {
    name: 'EventEdit',
    props: [ 'event' ],
}
</script>
